import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Whatsapp from '../Shared/Whatsapp'

const Layout = ({ children }) => {
  return (
    <>
      <Header></Header>
      <main className="w-full relative">
        {children}
      </main>
      <Footer></Footer>
      <Whatsapp></Whatsapp>
    </>
  )
}

export default Layout
