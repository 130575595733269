import { Link } from 'gatsby'
import React from 'react'
import logo from '../../../images/svg/logo.svg'

const Footer = () => {
  return (
    <footer className="flex md:flex-row md:justify-evenly gap-y-5 flex-col justify-center items-center bg-primary p-10 px-3">
      <div className="px-4 text-center md:text-left">
        <img className="w-32" src={logo} alt="Cursinho Pró Logo" />
      </div>
      <div className="px-4 text-center md:text-left">
        <h3 className="text-xl font-bold text-gray-800">
          <span className="text-white">ACOMPANHE</span>
        </h3>
        <ul>
          <li className="text-white py-2">
            <a href="https://instagram.com/profloripa" target="_blank" rel="noopener noreferrer">
              Instagram
            </a>
          </li>
          <li className="text-white py-2">
            <a href="https://facebook.com/profloripa" target="_blank" rel="noopener noreferrer">
              Facebook
            </a>
          </li>
          <li className="text-white py-2">
            <a href="https://twitter.com/cursinhopro" target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
          </li>
        </ul>
      </div>

      <div className="px-4 text-center md:text-left">
        <h3 className="text-xl font-bold text-gray-800">
          <span className="text-white">SOBRE O Cursinho Pró</span>
        </h3>
        <ul>
          <li className="text-white py-2">
            <Link to="/" target="_blank" rel="noopener noreferrer">
              Conhecer mais
            </Link>
          </li>
          <li className="text-white py-2">
            <Link to="/#teachers" target="_blank" rel="noopener noreferrer">
              Equipe
            </Link>
          </li>
          <li className="text-white py-2">
            <Link to="/#approved" target="_blank" rel="noopener noreferrer">
              Aprovados
            </Link>
          </li>
        </ul>
      </div>

      <div className="px-4 text-center md:text-left">
        <h3 className="text-xl font-bold text-gray-800">
          <span className="text-white">REDE PRÓ</span>
        </h3>
        <ul>
          <li className="text-white py-2">
            <Link href="/" target="_blank" rel="noopener noreferrer">
              Cursinho Pró
            </Link>
          </li>
          <li className="text-white py-2">
            <a href="https://prouniversidade.com.br/proaovivo" target="_blank" rel="noopener noreferrer">
              Pró Ao Vivo
            </a>
          </li>
          <li className="text-white py-2">
            <a href="https://prouniversidade.com.br/reforca/" target="_blank" rel="noopener noreferrer">
              Reforça Pró
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
