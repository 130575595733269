import { Link } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

import logo from "../../../images/svg/logo.svg";

const Header = ({ path, children }) => {
  return (
    <>
      <Helmet>
        <title>Cursinho Pró</title>

        {/* font */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&family=Raleway:wght@300;400;500;700&family=Roboto:wght@300;400;500;700&family=Gloria+Hallelujah&display=swap"
          rel="stylesheet"
        ></link>

        {/* <!-- Google Tag Manager --> */}
        <script>
          {`(function (w, d, s, l, i) {
                w[l] = w[l] || []; w[l].push({
                  'gtm.start':
                new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-5RWX3LB');`}
        </script>
        {/* <!-- End Google Tag Manager --> */}

        {/* <!-- Google tag (gtag.js) --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-803682816"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-803682816'); `} 
        </script>
        {/* <!-- END Google tag (gtag.js) --> */}

        {/* <!-- Facebook Pixel Code --> */}
        <script>
          {`
                  !function (f, b, e, v, n, t, s) {
              if (f.fbq) return; n = f.fbq = function () {
                    n.callMethod ?
                      n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                  };
                  if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                  n.queue = []; t = b.createElement(e); t.async = !0;
                  t.src = v; s = b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t, s)
            }(window, document, 'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '166635714060446');
                  fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=166635714060446&ev=PageView&noscript=1" />`}
        </noscript>
        {/* <!-- End Facebook Pixel Code --> */}

        <script>{`fbq('track', 'ViewContent');`}</script>

        <script>{` fbq('track', 'Lead');`}</script>
        <script
          id="llpixel"
          src="https://pixel.leadlovers.app/scripts/llpixel.js"
          data-key="E349F3C021"
          type="text/javascript"
        ></script>

        <script>{`
          (function(ll,mo,n,i,t,o,r){ll['LeadLoversMonitorObject'] = o;ll[o]=ll[o]||function(){
            (ll[o].q = ll[o].q || []).push(arguments);};r=mo.createElement(n);
          r.src=i;r.type=t;r.async=1;r.defer=1;mo.body.appendChild(r);
  })(window,document,'script','https://lib.leadlovers.site/scripts/monitor/index.js','module','mnt');
          mnt('init', 'ba0db440-f836-4874-9d30-c6a05cd10015');
          mnt('scout');
          mnt('capture');
        `}</script>
      </Helmet>

      <nav className="bg-primary py-2 md:py-2">
        <div className="container px-4 mx-auto justify-between flex md:items-center">
          <div className="flex justify-between items-center">
            <Link to="/" className="font-bold text-xl text-indigo-600">
              <img className="h-10" src={logo} alt="Cursinho Pró logo" />
            </Link>
          </div>

          <div
            className="flex-col md:flex-row md:ml-auto mt-3 md:mt-0"
            id="navbar-collapse"
          >
            <Link
              to="/#about"
              className="hidden md:inline-block p-2 cursor-pointer lg:px-4 md:mx-2 text-white font-semibold rounded-lg hover:bg-accent-400 transition-colors duration-300"
            >
              Quem somos
            </Link>
            <Link
              to="/#courses"
              className="hidden md:inline-block p-2 cursor-pointer lg:px-4 md:mx-2 text-white font-semibold rounded-lg hover:bg-accent-400 transition-colors duration-300"
            >
              Cursos
            </Link>
            <Link
              to="/#location"
              className="hidden md:inline-block p-2 cursor-pointer lg:px-4 md:mx-2 text-white font-semibold rounded-lg hover:bg-accent-400 transition-colors duration-300"
            >
              Contato
            </Link>
            <Link
              to="/#courses"
              className="hidden md:inline-block p-2 cursor-pointer lg:px-4 md:mx-2 text-white font-semibold rounded-lg hover:bg-accent-400 bg-accent transition-colors duration-300"
            >
              Matricule-se já
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
