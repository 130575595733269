import React from 'react'
import wpp from '../../../images/svg/wpp.svg'

const Whatsapp = () => {
  return (
    <a href="https://api.whatsapp.com/send?phone=554888111499%20&text=Ol%C3%A1%2C%20gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%20o%20curso%20Pr%C3%B3%20Floripa." 
    target="_blank" 
    rel="noreferrer"
    className="fixed bottom-10 right-10 bg-green-500 rounded-full h-15 w-15 p-3 hvr-grow border-2 border-white shadow-md">
      <img className='w-full' src={wpp} alt="" />
    </a>
  )
}

export default Whatsapp
